import React from "react"

import FirstBriefIntroImage from "./images/FirstBriefIntroImage"
import FirstBriefIntroImageMobile from "./images/FirstBriefIntroImageMobile"
import SecondBriefIntroImage from "./images/SecondBriefIntroImage"
import SecondBriefIntroImageMobile from "./images/SecondBriefIntroImageMobile"
import ThirdBriefIntroImage from "./images/ThirdBriefIntroImage"
import FourthBriefIntroImage from "./images/FourthBriefIntroImage"
import Arrow from "../../assets/images/icons/arrow_intro.svg"

import "./BriefIntroSectionCommission.scss"

const BriefIntroCommissionSection = () => {
  return (
    <section className="brief-intro-commissions-section">
      <div className="brief-intro-commissions-section__container">
        <div className="brief-intro-commissions-section__container__one">
          <div className="brief-intro-commissions-section__container__one__text">
            JESSICA <span>CoPPET</span>
          </div>
          <div className="brief-intro-commissions-section__container__one__image">
            <FirstBriefIntroImage />
          </div>
          <img
            className="brief-intro-commissions-section__container__one__arrow"
            src={Arrow}
          />
          <span className="brief-intro-commissions-section__container__one__description">
            HANDS, 2020
          </span>

          <div className="brief-intro-commissions-section__container__one__divider" />
        </div>
        <div className="brief-intro-commissions-section__container__one__mobile">
          <div className="brief-intro-commissions-section__container__one__mobile__image">
            <FirstBriefIntroImageMobile />
          </div>
          <div className="brief-intro-commissions-section__container__one__mobile__text">
            CoPPET
          </div>
          <img
            className="brief-intro-commissions-section__container__one__mobile__arrow"
            src={Arrow}
          />
          <span className="brief-intro-commissions-section__container__one__mobile__description">
            HANDS, 2020
          </span>
          <div className="brief-intro-commissions-section__container__one__mobile__divider" />
        </div>
        <div className="brief-intro-commissions-section__container__two">
          <div className="brief-intro-commissions-section__container__two__divider" />
          <span className="brief-intro-commissions-section__container__two__description">
            FRIDA, 2020
          </span>
          <img
            className="brief-intro-commissions-section__container__two__arrow"
            src={Arrow}
          />
          <div className="brief-intro-commissions-section__container__two__text">
            IS AVAILABLE <span>For</span>
          </div>
          <div className="brief-intro-commissions-section__container__two__image">
            <SecondBriefIntroImage />
          </div>
        </div>
        <div className="brief-intro-commissions-section__container__two__mobile">
          <div className="brief-intro-commissions-section__container__two__mobile__divider" />
          <span className="brief-intro-commissions-section__container__two__mobile__description">
            FRIDA, 2020
          </span>
          <img
            className="brief-intro-commissions-section__container__two__mobile__arrow"
            src={Arrow}
          />
          <div className="brief-intro-commissions-section__container__two__mobile__text">
            IS AVAILABLE FOR
          </div>
        </div>
        <div className="brief-intro-commissions-section__container__three">
          <div className="brief-intro-commissions-section__container__three__image one">
            <ThirdBriefIntroImage />
          </div>
          <div className="brief-intro-commissions-section__container__three__text">
            Commissions,
          </div>
          <div className="brief-intro-commissions-section__container__three__image two">
            <ThirdBriefIntroImage />
          </div>
          <img
            className="brief-intro-commissions-section__container__three__arrow"
            src={Arrow}
          />
          <span className="brief-intro-commissions-section__container__three__description">
            TINA, 2020
          </span>
          <div className="brief-intro-commissions-section__container__three__divider" />
        </div>
        <div className="brief-intro-commissions-section__container__three__mobile">
          <div className="brief-intro-commissions-section__container__three__mobile__image">
            <SecondBriefIntroImageMobile />
          </div>
          <div className="brief-intro-commissions-section__container__three__mobile__text">
            CoLlAb-
          </div>
          <img
            className="brief-intro-commissions-section__container__three__mobile__arrow"
            src={Arrow}
          />
          <span className="brief-intro-commissions-section__container__three__mobile__description">
            TINA, 2020
          </span>
          <div className="brief-intro-commissions-section__container__three__mobile__divider" />
        </div>
        <div className="brief-intro-commissions-section__container__four">
          <div className="brief-intro-commissions-section__container__four__text desktop-only">
            CoLlAboratIons,
          </div>
          <div className="brief-intro-commissions-section__container__four__text mobile-only">
            oratIons,
          </div>
          <div className="brief-intro-commissions-section__container__four__image">
            <FourthBriefIntroImage />
          </div>
          <img
            className="brief-intro-commissions-section__container__four__arrow"
            src={Arrow}
          />
          <span className="brief-intro-commissions-section__container__four__description">
            DARK BEAUTY, 2020
          </span>
          <div className="brief-intro-commissions-section__container__four__divider" />
        </div>
        <div className="brief-intro-commissions-section__container__five">
          <div className="brief-intro-commissions-section__container__five__divider" />
          <span className="brief-intro-commissions-section__container__five__description">
            HANDS, 2020
          </span>
          <img
            className="brief-intro-commissions-section__container__five__arrow"
            src={Arrow}
          />
          <div className="brief-intro-commissions-section__container__five__image one">
            <FirstBriefIntroImage />
          </div>
          <div className="brief-intro-commissions-section__container__five__text">
            & Commercial<span> Work.</span>
          </div>
        </div>
        <div className="brief-intro-commissions-section__container__five__mobile">
          <div className="brief-intro-commissions-section__container__five__mobile__divider" />
          <span className="brief-intro-commissions-section__container__five__mobile__description">
            HANDS, 2020
          </span>
          <img
            className="brief-intro-commissions-section__container__five__mobile__arrow"
            src={Arrow}
          />
          <div className="brief-intro-commissions-section__container__five__mobile__text">
            Work.
          </div>
          <div className="brief-intro-commissions-section__container__five__image">
            <FirstBriefIntroImage />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BriefIntroCommissionSection
