import React from "react"

import Link from "../../utils/link"

import JessicaWorkingImageLarge from "./ImageContainers/JessicaWorkingImageLarge"
import JessicaWorkingImageSmall from "./ImageContainers/JessicaWorkingImageSmall"
import "./CollaborateSection.scss"

const descText = [
  "For all commercial projects and collaborations, please get in contact, and fill in the following questionnaire to provide specific information around your project and any licenses you may require.",
  "I’ll respond with a detailed quote within 3 business days, along with my current availability for completing the work and an estimated completion date.",
  "Clients have included Hapercollins NY, Google, and the Australian Open…",
  "I look forward to working with you!",
]

const CollaborateSection = () => {
  return (
    <section className="collaborate-section">
      <div className="collaborate-section__container">
        <div className="collaborate-section__category">
          <div className="collaborate-section__category__description">
            <div className="collaborate-section__category__description__title">
              Collaborate
            </div>
            <div className="horizontal-rule" />
            <div className="collaborate-section__category__description__text">
              {descText.map((el, i) => <p>{ el }</p>) }
            </div>
            <div className="collaborate-section__category__description__button-container">
              <Link
                to="https://jessicacoppet.typeform.com/to/GTZVyfJv"
                className="collaborate-section__category__description__button"
              >
                Collaborate
              </Link>
            </div>
          </div>
          <div className="collaborate-section__category__image">
            <JessicaWorkingImageLarge />
            <JessicaWorkingImageSmall />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CollaborateSection
