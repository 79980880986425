import React from "react"

import Link from "../../utils/link"

import JessicaImageLarge from "./ImageContainers/JessicaImageLarge"
import JessicaImageSmall from "./ImageContainers/JessicaImageSmall"

import "./CommissionsSection.scss"

const descText = [
  "To order a custom illustration or commission piece, you will need to fill out a questionnaire to provide specific information on the size, colour, and amount of subjects to be illustrated, along with any additional typography or background imagery. Please note – I require a reference image for all commissions.",
  "I’ll respond with a detailed quote within 3 business days, along with my current availability for completing the work and an estimated completion date.",
  "Here are a few examples of commissions that I have created over the past year.",
  "*Please note these pieces are examples only, and are not available for sale as prints.",
]

const CommissionsSection = () => {
  return (
    <section className="commissions-section">
      <div className="commissions-section__container">
        <div className="commissions-section__category">
          <div className="commissions-section__category__image">
            <JessicaImageLarge />
            <JessicaImageSmall />
          </div>
          <div className="commissions-section__category__description">
            <div className="commissions-section__category__description__title">
              Commissions
            </div>
            <div className="horizontal-rule" />
            <div className="commissions-section__category__description__text">
              {descText.map((el, i) => <p>{ el }</p>) }
            </div>
            <div className="commissions-section__category__description__button-container">
              <Link
                to="https://jessicacoppet.typeform.com/to/iXUJ7Q"
                className="commissions-section__category__description__button"
              >
                Order a custom illustration
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CommissionsSection
