import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const JessicaImageLarge = () => {
  const data = useStaticQuery(query)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      className="large-only"
      alt="Jessica Face"
    />
  )
}

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "jessica_face.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default JessicaImageLarge
