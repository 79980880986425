import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const WorkSampleOne = () => {
  const data = useStaticQuery(query)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Illustration"
    />
  )
}

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "work-samples/Commission-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default WorkSampleOne
