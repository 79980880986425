import React from "react"

import WorkSampleOne from "./ImageContainers/WorkSampleOne"
import WorkSampleTwo from "./ImageContainers/WorkSampleTwo"
import WorkSampleThree from "./ImageContainers/WorkSampleThree"
import WorkSampleFour from "./ImageContainers/WorkSampleFour"

import WorkSampleFive from "./ImageContainers/WorkSampleFive"
import WorkSampleSix from "./ImageContainers/WorkSampleSix"
import WorkSampleSeven from "./ImageContainers/WorkSampleSeven"
import WorkSampleEight from "./ImageContainers/WorkSampleEight"

import SliderLeft from "../../assets/images/icons/slider-left.svg"
import SliderRight from "../../assets/images/icons/slider-right.svg"

import "./WorkSamples.scss"

const WorkSamples = () => {
  const slideAmount = 250

  const [moved, setMoved] = React.useState(0)
  const [isSlideLeftDisabled, setIsSlideLeftDisabled] = React.useState(true)
  const [isSlideRightDisabled, setIsSlideRightDisabled] = React.useState(false)
  const scroller = React.useRef()

  React.useEffect(() => {
    if (moved === 0) {
      setIsSlideLeftDisabled(true)
    } else {
      setIsSlideLeftDisabled(false)
    }

    if (moved * -1 >= 8 * slideAmount) {
      setIsSlideRightDisabled(true)
    } else {
      setIsSlideRightDisabled(false)
    }
  }, [moved])

  const slideLeft = () => {
    if (moved === 0) {
      return null
    } else {
      setMoved(moved + slideAmount)
      scroller.current.scrollLeft -= 200
    }
  }
  const slideRight = () => {
    if (moved * -1 >= 8 * slideAmount) {
      return null
    } else {
      setMoved(moved - slideAmount)
      scroller.current.scrollLeft += 200
    }
  }

  return (
    <section className="work-samples">
      <div
        className="work-samples__products"
        ref={scroller}
        style={{
          //transform: `translateX(${moved}vw)`,
          scrollBehaviour: "smooth",
        }}
      >
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleOne />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleTwo />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleThree />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleFour />
            </div>
          </div>
        </div>

        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleFive />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleSix />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleSeven />
            </div>
          </div>
        </div>
        <div className="work-samples__products__product-card">
          <div className="work-samples__products__product-card__image">
            <div>
              <WorkSampleEight />
            </div>
          </div>
        </div>
      </div>
      <div className="work-samples__controls">
        <button
          className={`work-samples__controls__button ${
            isSlideLeftDisabled ? "is-disabled" : ""
          }`}
          onClick={slideLeft}
        >
          <img src={SliderLeft} />
        </button>
        <div className="work-samples__controls__title">Artwork</div>
        <button
          className={`work-samples__controls__button ${
            isSlideRightDisabled ? "is-disabled" : ""
          }`}
          onClick={slideRight}
        >
          <img src={SliderRight} />
        </button>
      </div>
      <div className="work-samples__horizontal-rule" />
    </section>
  )
}

export default WorkSamples
