import React from "react"

import SEO from "../components/seo"

import BriefIntroCommissionSection from "../components/BriefIntro/BriefIntroCommissionSection"

import CommissionsSection from "../components/Commissions/CommissionsSection"
import WorkSamples from "../components/Commissions/WorkSamples"
import CollaborateSection from "../components/Commissions/CollaborateSection"

const CommissionsPage = () => {
  React.useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  })

  return (
    <>
      <SEO
        path="/commissions"
        title="Commissions and Collaborations - Jessica Coppet"
      />
      <BriefIntroCommissionSection />
      <CommissionsSection />
      <WorkSamples />
      <CollaborateSection />
    </>
  )
}

export default CommissionsPage
