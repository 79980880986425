import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const JessicaWorkingImageSmall = () => {
  const data = useStaticQuery(query)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      className="small-only"
      alt="Jessica Working"
    />
  )
}

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "jessica_working_2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default JessicaWorkingImageSmall
